import React from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { UISref, useRouter } from '@wix/tpa-router/react';

import {
  selectGroup,
  selectFeedItems,
  selectFeedStatuses,
} from 'store/selectors';

import { useController } from 'common/context/controller';
import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { EmptyState } from 'wui/EmptyState';
import { ErrorState } from 'wui/ErrorState';

import { FeedItem, FeedItemSkeleton } from 'common/components/FeedItem';
import settingsParams from 'FeedWidget/settingsParams';

import classes from './Feed.scss';

interface IProps {
  groupId: string;
}

export function Feed(props: IProps) {
  const { groupId } = props;
  const router = useRouter();
  const settings = useSettings();
  const { t } = useTranslation();
  const { feed$ } = useController();

  const items = useSelector(selectFeedItems);
  const group = useSelector(selectGroup(groupId));
  const statuses = useSelector(selectFeedStatuses);
  const limit = settings.get(settingsParams.postsCount);

  if (statuses.fetch.pending) {
    return (
      <>
        <FeedItemSkeleton />
        <FeedItemSkeleton />
        <FeedItemSkeleton />
      </>
    );
  }

  if (statuses.fetch.error) {
    return (
      <ErrorState
        onRetry={handleRetry}
        title={t('groups-web.discussion.error-state.title')}
        actionLabel={t('groups-web.discussion.error-state.cta')}
        subtitle={
          <Trans
            i18nKey={t('groups-web.discussion.error-state.owner.message')}
            components={[
              <span key="0">Try reloading</span>,
              <br key="1" />,
              <span key="2">contact</span>,
            ]}
          />
        }
      />
    );
  }

  if (!items.length) {
    return (
      <EmptyState
        title={t('groups-web.discussion.first.title')}
        subtitle={t('groups-web.discussion.first.description')}
        action={
          <Button onClick={redirectToGroupPage}>
            {t('groups-web.discussion.first.button')}
          </Button>
        }
        variant="section"
      />
    );
  }

  return (
    <Box className={classes.feed} direction="vertical">
      {items.map((item) => (
        <FeedItem
          promote
          truncate
          key={item.feedItemId}
          item={{
            ...item,
            permissions: {
              ...item.permissions,
              canUpdate: false,
              canDelete: false,
            },
          }}
        />
      ))}

      <Box align="center">
        <UISref state="group" params={{ slug: group.slug }}>
          <Button as="a">{t('groups-web.feed.show-more.label')}</Button>
        </UISref>
      </Box>
    </Box>
  );

  function handleRetry() {
    feed$.fetch(groupId, { limit });
  }

  function redirectToGroupPage() {
    router.go('group', { slug: group.slug });
  }
}

Feed.displayName = 'Feed';
