import { curryRight } from 'lodash';
import { createSelector } from 'reselect';

import { selectCurrentUser, selectReactions } from 'store/selectors';

import { REACTION_CODES } from './constants';

export const selectUserReaction = curryRight(
  createSelector([selectReactions, selectCurrentUser], (reactions, user) => {
    const suitable = reactions?.reactions.filter((reaction) => {
      const isCurrentUser = reaction.user.siteMemberId === user.siteMemberId;
      const isReaction = REACTION_CODES.includes(reaction.code);

      return isCurrentUser && isReaction;
    });

    return suitable?.[suitable.length - 1];
  }),
  2,
);
